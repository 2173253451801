import { ThemeProvider } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

import PhoneHeader from '../Headers/PhoneHeader';
import StickyHeader from '../Headers/StickyHeader';

import theme from './muiTheme';

type Props = {
  children: React.ReactNode;
  discussion?: boolean;
}

const Layout = (props: Props): JSX.Element => {
  return (
    <ThemeProvider theme={theme}>
      <StickyHeader discussion={props.discussion} />
      <PhoneHeader discussion={props.discussion} />
      <main>{props.children}</main>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  discussion: PropTypes.bool
};

export default Layout;