import React from 'react';

import 'styles/main.scss';
import Layout from 'components/Layout';
import SEO from 'components/SEO';

const NotFoundPage = (): JSX.Element => (
  <Layout> 
    <SEO title="404"/>
    <div className="error-page">
      <div className="error-page__text">
        <h1>NIE ZNALEZIONO</h1>
        <p>Próbowałeś przejść na podstronę, która nie istnieje. Smuteczek...</p>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
