import { createMuiTheme } from '@material-ui/core/styles';

const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 1280,
  xl: 1680
};

const theme = createMuiTheme({ breakpoints: { values: breakpoints } });

export default theme;